import { combineReducers } from 'redux';

export function boopContract(state = {}, action) {
  switch (action.type) {
    case 'BOOP_CONTRACT_LOADED':
      return { ...state, loaded: true, contract: action.contract }
    case 'BOOP_DATA_LOADED':
      return { ...state, loaded: true, data: action.data }
    default:
      return state
  }
}

const BoopReducer = combineReducers({
   boopContract,
})

export default BoopReducer