export function bp_food_ContractLoaded(contract) {
    return {
      type: 'BASE_PETS_FOOD_CONTRACT_LOADED',
      contract
    }
  }
  
  export function bp_food_MetaDataLoaded(metadata) {
    return {
      type: 'BASE_PETS_FOOD_METADATA_LOADED',
      metadata
    }
  }

  export function bp_food_BalancesLoaded(balances) {
    return {
      type: 'BASE_PETS_FOOD_BALANCES_LOADED',
      balances
    }
  }