export function boopContractLoaded(contract) {
    return {
      type: 'BOOP_CONTRACT_LOADED',
      contract
    }
  }
// export function ContractLoaded(contract) {

// export function cusdDataLoaded(data) {
  
export function boopDataLoaded(data) {
  return {
    type: 'BOOP_DATA_LOADED',
    data
  }
}
