import { useState, useEffect} from 'react'
import { connect } from 'react-redux'
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react'

import { useDispatch } from 'react-redux'

import { update } from '../store/interactions'
import { basepetsUpdate } from '../store/base_pets/interactions'
import { bpfoodUpdate } from '../store/bp_food/interactions'
import { boopUpdate } from '../store/boop/interactions'


// import { createStructuredSelector } from 'reselect'

// import BPFood from './BPFood'
// import BuyFood from './BuyFood'
import Countdown from './Countdown'
import '../Styles/PetCard.css'


import { bpfoodBalancesSelector, bpfoodMetaDataSelector } from '../store/bp_food/selectors'
import { basepetsMetaDataSelector } from '../store/base_pets/selectors'
import { boopDataSelector } from '../store/boop/selectors'

// import { BuildStructure } from './BuildStructure'
// import { UserStructure } from './UserStructure'

// import {images} from '../images'



import { BrowserProvider, Contract, formatUnits } from 'ethers'

import basepetsABI from '../backend/abis/BasePets.json'
import { farmUpdate } from '../store/farm/interactions'


const { BASE_PETS_ADDRESS } = require('../contracts.json')


// The ERC-20 Contract ABI, which is a common contract interface
// for tokens (this is the Human-Readable ABI format)


const BasePets = (props) => {

  const dispatch = useDispatch()
  const { address, chainId, isConnected } = useWeb3ModalAccount()
  const { walletProvider } = useWeb3ModalProvider()

  const [feedAmount, setFeedAmount] = useState()
  const [petToFeed, setPetToFeed] = useState()
  const [foodID, setFoodID] = useState()


  


  // const account = ""
  // let address = ""
  // address === undefined ? address = '' : address = address

  const CallUpdates = async () => {
    // let account = useAccount()]
    // account.address === undefined ? address = '' : address = account.address.toString()
    
    
    let new_address = isConnected ? address.toString() : ''
    await update(dispatch, new_address)
    await farmUpdate(dispatch, new_address)
    await basepetsUpdate(dispatch, new_address)
    await bpfoodUpdate(dispatch, new_address)
    await boopUpdate(dispatch, new_address)

  }
  useEffect(() => {
    CallUpdates()
  }, [address])

  const foodIDInput = (food_id) => {
    setFoodID(food_id)
  }


  return (
    <>
    <h1>Your Pets</h1>

      {
          props.user_pets_data === undefined ||
          props.user_pets_data === null ||
          props.foodData === undefined ||
          props.foodData === null ||
          props.balance === undefined ||
          props.user_pets_data === null ? <div>loading...</div> :
          <div>


      <div className='Container-All-Card'>
        {props.user_pets_data[0].pet_id !== 0 ? (props.user_pets_data.map((pet, index) => {
            return (
                <div className='pet-card' key={index}>
                  <h1>{`Pet ID: ${pet.pet_id}`}</h1>
                  <div>
                  <img src={require(`../images/${pet.pet_id}.png`)}
                    width={'60%'}
                    alt={pet.pet_id}
                  />
                  </div>
                  <div>
                    <Countdown
                      difference={((Number(pet.fed_until)) * 1000) }
                    />
                  </div>
                </div>
            )
        })) : (<div></div>) }
        </div>
        </div>
      }
      </>
    )
}



function mapStateToProps(state) {
    return {
        balances: bpfoodBalancesSelector(state),
        foodData: bpfoodMetaDataSelector(state),
        user_pets_data: basepetsMetaDataSelector(state),
        balance: boopDataSelector(state),
    }
  }
  
  export default connect(mapStateToProps)(BasePets);


// const mapStateToProps =  createStructuredSelector({
//   resources: bpfoodBalancesSelector,
// //   resourcesContract: bpfoodContractSelector,
//   user_pets_data: basepetsMetaDataSelector,
//   balance: boopDataSelector,
//   // activeMarkets: activeMarketsSelector,
//   // weightedAverages: weightedAveragesSelector,
// })

// export default connect(mapStateToProps)(BasePets)


