export function ethersProviderLoaded(connection) {
    return {
      type: 'ETHERS_PROVIDER_LOADED',
      connection
    }
  }
  
  export function ethersNetworkLoaded(network) {
    return {
      type: 'ETHERS_NETWORK_LOADED',
      network
    }
  }
  
  export function ethersAccountLoaded(account) {
    return {
      type: 'ETHERS_ACCOUNT_LOADED',
      account
    }
  }
  
  export function ethersBalanceLoaded(balance) {
    return {
      type: 'ETHERS_BALANCE_LOADED',
      balance
    }
  }