import { useState, useEffect} from 'react'
import { connect } from 'react-redux'
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react'

import { useDispatch } from 'react-redux'

import { update } from '../store/interactions'
import { basepetsUpdate } from '../store/base_pets/interactions'
import { bpfoodUpdate } from '../store/bp_food/interactions'
import { boopUpdate } from '../store/boop/interactions'

import Countup from './Countup'
import '../Styles/PetCard.css'


import { bpfoodBalancesSelector } from '../store/bp_food/selectors'
import {farmContractSelector, farmBalancesSelector, farmGatherablesSelector, farmMakeablesSelector, farmCostsSelector, farmMetaDataSelector,} from '../store/farm/selectors'
import { boopDataSelector } from '../store/boop/selectors'


import { BrowserProvider, Contract } from 'ethers'

import { farmUpdate } from '../store/farm/interactions'


import farmABI from '../backend/abis/Farm.json'
const { FARM_ADDRESS } = require('../contracts.json')

const Farm = (props) => {

  const dispatch = useDispatch()
  const { address, chainId, isConnected } = useWeb3ModalAccount()
  const { walletProvider } = useWeb3ModalProvider()

  const [structureID, setStructureID] = useState()

  const CallUpdates = async () => {
    
    let new_address = isConnected ? address.toString() : ''
    await update(dispatch, new_address)
    await farmUpdate(dispatch, new_address)
    await bpfoodUpdate(dispatch, new_address)
    await boopUpdate(dispatch, new_address)

  }
  useEffect(() => {
    CallUpdates()
  }, [address])

  const buildFarm = async () => {

    if(!isConnected) throw Error("User disconnected")

    const ethersProvider =  new BrowserProvider(walletProvider)
    const signer = await ethersProvider.getSigner()
    // The Contract object
    const farm = new Contract(FARM_ADDRESS, farmABI.abi, signer)
    let tx = await farm.buildFarm()
    await tx.wait()
    await CallUpdates()
  }


  const buildStructure = async (structureID) => {

    if(!isConnected) throw Error("User disconnected")

    const ethersProvider =  new BrowserProvider(walletProvider)
    const signer = await ethersProvider.getSigner()
    // The Contract object
    const farm = new Contract(FARM_ADDRESS, farmABI.abi, signer)
    let tx = await farm.buildStructure(structureID)
    await tx.wait()
    await CallUpdates()
  }

  const upgrade = async (structureID) => {

    if(!isConnected) throw Error("User disconnected")

    const ethersProvider =  new BrowserProvider(walletProvider)
    const signer = await ethersProvider.getSigner()
    // The Contract object
    const farm = new Contract(FARM_ADDRESS, farmABI.abi, signer)
    let tx = await farm.upgrade(structureID)
    await tx.wait()
    await CallUpdates()
  }
  const organicUpgrade = async (structureID) => {

    if(!isConnected) throw Error("User disconnected")

    const ethersProvider =  new BrowserProvider(walletProvider)
    const signer = await ethersProvider.getSigner()
    // The Contract object
    const farm = new Contract(FARM_ADDRESS, farmABI.abi, signer)
    let tx = await farm.organicUpgrade(structureID)
    await tx.wait()
    await CallUpdates()
  }

  const gather = async (structureID) => {

    if(!isConnected) throw Error("User disconnected")

    const ethersProvider =  new BrowserProvider(walletProvider)
    const signer = await ethersProvider.getSigner()
    // The Contract object
    const farm = new Contract(FARM_ADDRESS, farmABI.abi, signer)
    let tx = await farm.gather(structureID)
    await tx.wait()
    await CallUpdates()
  }

  
  const makePetFood = async (structureID) => {

    if(!isConnected) throw Error("User disconnected")

    const ethersProvider =  new BrowserProvider(walletProvider)
    const signer = await ethersProvider.getSigner()
    // The Contract object
    const farm = new Contract(FARM_ADDRESS, farmABI.abi, signer)
    let tx = await farm.makePetFood(structureID)
    await tx.wait()
    await CallUpdates()
  }

  const retrunRequiredNames = (ids) => {
    let names = ''
    ids.map((id, index, ids) => {
      if(index + 1 === ids.length) names += props.farm_metadata[0][id -1].resourceName
      else names += props.farm_metadata[0][id -1].resourceName  + ', '
    })
    return names
  }


  return (
    <>
    <h1>Hello Farmer!</h1>
    <h3>hard work but it's honest!</h3>

      {
          props.balances === undefined ||
          props.farm_balances === undefined ||
          props.gatherables === undefined ||
          props.farmCosts === undefined ||
          props.farm_metadata === undefined ||
          props.farm_metadata === null ||
          props.balance === undefined ? <div> loading...</div> :
          <div>
{/* [FARMS, FOODS, OWNED_FARMS, hasFarm] */}

      <div className='Container-All-Card'>
        {props.farm_metadata[3] ? (props.farm_metadata[0].map((farm, index) => {
            return (
                <div className='farm-card' key={index}>
                  <div className='farm-title'>
                    <h1>{`${farm.resourceName}`} 

                    </h1>

                    <img src={require(`../images_farm/${farm.resourceName}.png`)}
                        width={'75px'}
                        alt={farm.resourceName}
                      />
                  </div>
                  
                  {props.farm_metadata[2][index].built ? 
                  <div>
                  <table className='farm-table'>
                    <tbody>
                        <tr>
                          <th>Level</th>
                          <th>Max Level</th>
                          <th>upgrade cost</th>
                          <th>upgrade?</th>
                          <th>organic?</th>
                          <th>balance</th>
                          <th>max gatherable</th>
                          <th></th>
                        </tr>
                        <tr>
                          <td><b>{props.farm_metadata[2][index].level}</b></td>
                          <td>
                          {farm.maxLevel}
                          </td>
                          <td>
                            {farm.buildCost / 10 * props.farm_metadata[2][index].level} $BOOP
                          </td>
                          <td>
                          {props.farm_metadata[2][index].level === farm.maxLevel ? "" :
                            <button
                              className='btn'
                              style={{border:'solid', borderColor:'green', height:'95%', fontSize:'90%'}}
                              onClick={() => upgrade(farm.resourceID)}>
                              <b>yes</b>
                            </button>
                          }
                          </td>
                          <td>
                          {props.farm_metadata[2][index].organic ?
                                  "its organic!"
                                :
                                  <button
                                    className='btn btn-primary'
                                    onClick={() => organicUpgrade(farm.resourceID)}>
                                    organify!
                                  </button>
                                }
                          </td>
                          <td>
                            {Number(props.farm_balances[index])}
                          </td>
                          <td>
                            {Number(props.gatherables[index])}
                          </td>
                          <td>
                            <button
                              className='btn btn-primary'
                              onClick={() => gather(farm.resourceID)}>
                              gather
                            </button>
                          </td>
                        </tr>

                    </tbody>
                  </table>
                        <div>
                          <Countup difference={((Number(props.farm_metadata[2][index].lastGathered)) * 1000) }
                          index={index}/>
                        </div>


                        
                    </div> 
                    :
                      <button
                        className='btn btn-primary'
                        onClick={() => buildStructure(farm.resourceID)}>
                        build it!
                      </button>
                    }
                  </div>
            )
        }))
        :
        (
        <div> 
          you need to build a farm!
          <div>
            <div>build cost: <b>{props.farmCosts[0]}</b> $BOOP</div>
            <button
              className='btn btn-primary'
              onClick={() => buildFarm()}>
              purchase
            </button>
          </div>
        </div>
        )}
        </div>
        <div><h1>Make Food!</h1>
        <br></br>
        {props.farm_metadata[1].map((food, index) => {
                    return (
                      <div>
                      <h1 style={{marginTop:'20px'}}>{food.name}</h1>
                      <table className='farm-table'>
                        <tbody>
                          <tr>
                          <th>balance</th>
                          <th>Max Make Amount</th>
                          <th>Require Inputs</th>
                          <th>Require Input Amounts</th>
                          </tr>
                          <tr>
                            <td>{Number(props.balances[index])}</td>
                            <td>{Number(props.makeables[index][0])}</td>
                            <td>
                            
                                {food.inputResourceIDs.map((id, index) => {
                                      {return (food.organic ?
                                        <tr>{"Organic " + props.farm_metadata[0][id -1].resourceName}</tr> :
                                        <tr>{props.farm_metadata[0][id -1].resourceName}</tr>
                                      )}
                                })}
                              

                                {/* {retrunRequiredNames(food.inputResourceIDs)} */}
                            </td>
                            <td>
                              <center>
                                {food.inputAmounts.map((amount) => {
                                  return <tr>{amount}</tr>
                                })}
                              </center>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      {props.makeables[index][0] > 0 ?
                          <button
                            className='btn btn-primary'
                            onClick={() => makePetFood(props.farm_metadata[1][index].bpFoodID)}>
                            let's make it!
                          </button> :
                          ""
                        }
                      </div>
                      )
        })}

        
        </div>
        </div>
      }
      </>
    )
}



function mapStateToProps(state) {
    return {
        balances: bpfoodBalancesSelector(state),
        contract : farmContractSelector(state),
        farm_balances : farmBalancesSelector(state),
        gatherables : farmGatherablesSelector(state),
        makeables : farmMakeablesSelector(state),
        farmCosts : farmCostsSelector(state),
        farm_metadata : farmMetaDataSelector(state),
        balance: boopDataSelector(state),
    }
  }
  
  export default connect(mapStateToProps)(Farm);
