import { ethers } from 'ethers'

import { update, loadProvider } from '../interactions'

import {
  bp_food_ContractLoaded,
  bp_food_BalancesLoaded,
  bp_food_MetaDataLoaded,
} from './actions'



import BP_FOOD from '../../backend/abis/BasePetFood.json'
const { BASE_PET_FOOD_ADDRESS } = require('../../contracts.json')

export const loadBPFood = async (dispatch, provider) => {
  try {
    const bp_food = new ethers.Contract(BASE_PET_FOOD_ADDRESS, BP_FOOD.abi, provider)
    dispatch(bp_food_ContractLoaded(bp_food))
    return bp_food
  } catch (e) {
    console.log('Error, loading bp_food contract: ', e)
    dispatch(bp_food_ContractLoaded(undefined))
    return undefined
  }
}

export const loadCurrentID = async (bp_food) => {


  try {
    const currentID = await bp_food.counter()


  
    // dispatch(bp_food_ContractLoaded(bp_food))
    return currentID.toString()

  } catch (e) {
    console.log('Error, loading bp_food current ID: ', e)
    // dispatch(bp_food_ContractLoaded(undefined))
    return undefined
  }
}

export const bpfoodUpdate = async (dispatch, address) => {
  try{
    let provider, bp_food

    provider = await loadProvider(dispatch)

    bp_food = await loadBPFood(dispatch, provider)
    let currentID = await loadCurrentID(bp_food)
    await fetchBPFoodBalances(dispatch, currentID, address, bp_food)
    await getFoodData(dispatch, currentID, bp_food)

  } catch (e) {
    console.log('Error, on bp_food update: ', e)
  }
}

export const fetchBPFoodBalances = async (dispatch, currentID, account, bp_food) => {
  try {
    let accounts = []
    let ids = []
    let balances


    if(currentID === undefined) {
      currentID = await loadCurrentID(bp_food)
    }


    if(account === undefined || account === '') {
      balances = []
      for (let i = 0; i < currentID; i++) {
        balances[i] = 0
      }
    } else {
      for (let i = 1; i < currentID; i++) {
        accounts.push(account)
        ids.push(i)
      }
      balances = await bp_food.balanceOfBatch(accounts, ids)

    }
    dispatch(bp_food_BalancesLoaded(balances))
    return balances
  } catch(e) {
    console.log('Error, unable to load bp_food balances', e)
    dispatch(bp_food_BalancesLoaded(undefined))
    return undefined
  }
}

export const getFoodData = async (dispatch, currentID, bp_food) => {
    let FOODS = []
    const FOODS_DATA = require('../DATA/food_data.json')
    try {
        // for(let i = 1; i < currentID; i++) {
        //     let data = await bp_food.food(i)

        //     FOODS.push({
        //       food_id: Number(data[0]),
        //       food_name: data[1],
        //       time_increase: Number(data[2]) / 86400,
        //       price: Number(data[3]) / 10**18,
        //     })
        // }

        for(let i = 0; i < FOODS_DATA.length; i++) {
          FOODS.push({
            food_id: FOODS_DATA[i].FOOD_ID,
            food_name: FOODS_DATA[i].NAME,
            time_increase: FOODS_DATA[i].TIME_INCREASE,
            price: FOODS_DATA[i].PRICE,
            reqIDs: FOODS_DATA[i].REQUIRED_IDS,
            reqAmounts: FOODS_DATA[i].REQUIRED_AMOUNTS,
          })
      }

        dispatch(bp_food_MetaDataLoaded(FOODS))
        console.log(FOODS)
        return FOODS
    } catch(e) {
        console.log('Error, unable to load bp_food', e)
        dispatch(bp_food_MetaDataLoaded(undefined))
        return undefined
    }
}