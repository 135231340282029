// import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
// import { Web3Modal } from '@web3modal/react'
// import { configureChains, createConfig, WagmiConfig } from 'wagmi'
// import { arbitrum, hardhat, mainnet, polygon } from 'wagmi/chains'


import Main from './components/Main';
import { connect } from 'react-redux'


import './App.css';


import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react'

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = '614c6790031c675a3297f8584d8003c3'

// 2. Set chains
const mainnet = {
  chainId: 1,
  name: 'Ethereum',
  currency: 'ETH',
  explorerUrl: 'https://etherscan.io',
  rpcUrl: 'https://cloudflare-eth.com'
}

const testnet = {
  chainId: 84532,
  name: 'Base-Sepolia',
  currency: 'ETH',
  explorerUrl: 'https://base-sepolia.blockscout.com',
  rpcUrl: 'https://cloudflare-eth.com'
}

// 3. Create modal
const metadata = {
  name: 'My Website',
  description: 'My Website description',
  url: 'https://mywebsite.com',
  icons: ['https://avatars.mywebsite.com/']
}

createWeb3Modal({
  ethersConfig: defaultConfig({ 
    metadata,
    defaultChainId: 1,
    enableEIP6963: true,
    enableInjected: true,
    enableCoinbase: true,
    chainImages: {
      1: 'https://example.com/ethereum.png',
    },
    rpcUrl: '...', // used for the Coinbase SDK
    tokenContracts: {
      1: '0x1941B6d5D8cB92074D8842511f013cBD5563150a',
    }
  }),
  chains: [mainnet],
  projectId
})

function App() {
  return <Main/>
}


// // const chains = [arbitrum, mainnet, polygon, hardhat]
// const chains = [hardhat]
// const projectId = "614c6790031c675a3297f8584d8003c3"

// const { publicClient } = configureChains(chains, [w3mProvider({ projectId })])
// const wagmiConfig = createConfig({
//   autoConnect: true,
//   connectors: w3mConnectors({ projectId, chains }),
//   publicClient
// })
// const ethereumClient = new EthereumClient(wagmiConfig, chains)

// function App() {
//   return (
//     <>
//     <WagmiConfig config={wagmiConfig}>
//       <Main />
//     </WagmiConfig>

//     <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
//     </>
//   );
// }


function mapStateToProps(state) {
  return {
  }
}

export default connect(mapStateToProps)(App)