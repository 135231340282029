import { combineReducers } from 'redux';
import BasePetsReducer from './base_pets/reducers';
import BPFoodReducer from './bp_food/reducers';
import BoopReducer from './boop/reducers'
import FarmReducer from './farm/reducers'


function ethers(state = {}, action) {
  switch (action.type) {
    case 'ETHERS_PROVIDER_LOADED':
      return { ...state, connection: action.connection }
    case 'ETHERS_NETWORK_LOADED':
      return { ...state, network: action.network }
    case 'ETHERS_ACCOUNT_LOADED':
      return { ...state, account: action.account }
    case 'ETHERS_BALANCE_LOADED':
      return { ...state, balance: action.balance }
    default:
      return state
  }
}


const rootReducer = combineReducers({
  ethers,
  BasePetsReducer,
  BPFoodReducer,
  BoopReducer,
  FarmReducer,
//   game,
})

export default rootReducer