import { combineReducers } from 'redux';

export function base_pets_contract(state = {}, action) {
  switch (action.type) {
    case 'BASE_PETS_CONTRACT_LOADED':
      return { ...state, loaded: true, contract: action.contract }
    case 'BASE_PETS_METADATA_LOADED':
      return { ...state, loaded: true, metadata: action.metadata }
    case 'BASE_PETS_COSTS_LOADED':
      return { ...state, loaded: true, costs: action.costs }
    case 'BASE_PETS_AVAILABLE_LOADED':
      return { ...state, loaded: true, available: action.available }
    default:
      return state
  }
}



const BasePetsReducer = combineReducers({
    base_pets_contract,
})

export default BasePetsReducer