import { ethers } from 'ethers'

import {
  ethersProviderLoaded,
  ethersNetworkLoaded,
  ethersAccountLoaded,
  ethersBalanceLoaded,
} from './actions'

import { useDispatch } from 'react-redux'

// require("dotenv").config();

let returnAddress = ''

const API_KEY_ONE = process.env.REACT_APP_ALCHEMY_KEY_ONE
const API_KEY_TWO = process.env.REACT_APP_ALCHEMY_KEY_TWO



export const loadProvider = async (dispatch) => {
  let API_KEY = Math.random() > 0.5 ? API_KEY_ONE : API_KEY_TWO
    try{
        window.ethereum.autoRefreshOnNetworkChange = false;
        // const provider = new ethers.JsonRpcProvider("http://127.0.0.1:8545/")
        const provider = new ethers.JsonRpcProvider(`https://base-sepolia.g.alchemy.com/v2/${API_KEY}`)
        dispatch(ethersProviderLoaded(provider))
        return provider
    } catch (e) {
      console.log('Error, load Ethers Provider: ', e)
    }
  }

  export const loadNetwork = async (dispatch) => {
    try{
      let provider = await loadProvider(dispatch)
  
      const { name } = await provider.getNetwork()
      const { chainId } = await provider.getNetwork()
      let network = name
      console.log(chainId)
      console.log(name)
      console.log(network)
      network = network.charAt(0).toUpperCase()+network.slice(1)
      dispatch(ethersNetworkLoaded(network))
      return network
    } catch (e) {
      dispatch(ethersNetworkLoaded('Wrong network'))
      console.log('Error, load network: ', e)
    }
  }

  export const loadAccount = async (dispatch) => {

    try{

      dispatch(ethersAccountLoaded(returnAddress.toString()))

    } catch (e) {
      console.log('Error, load account: ', e)
    }
  }

  export const loadBalance = async (dispatch, provider, account) => {
    try {
      const etherBalance = await provider.getBalance(account)
      dispatch(ethersBalanceLoaded((Number(etherBalance)/10**18).toFixed(5)))
    } catch (e) {
      console.log('Error, load balance: ', e)
    }
  }

  export const update = async (dispatch, address) => {
    try{
      returnAddress = address === undefined ? '' : address
      // returnAddress = address
  
      let provider = await loadProvider(dispatch)
  
      if(returnAddress){
        await loadBalance(dispatch, provider, returnAddress)
      }
    } catch (e) {
      console.log('Error, update data: ', e)
    }
  }