import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import {farmMetaDataSelector} from '../store/farm/selectors'

import '../Styles/Countdown.css'




const Countup = (props) => {

    const [fedUntilTime, setFedUntilTime] = useState(false)

    const [days, setDays] = useState(0)
    const [hours, setHours] = useState(0)
    const [minutes, setMinutes] = useState(0)
    const [seconds, setSeconds] = useState(0)

    useEffect(() => {


        const interval = setInterval(() => {
            const now = new Date().getTime()

            const difference = now - props.difference

            const d = Math.floor(difference / (1000 * 60 * 60* 24))
            setDays(d)

            const h = Math.floor(
                (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            )
            setHours(h)

            const m = Math.floor(
                (difference % (1000 * 60 * 60 )) / (1000 * 60)
            )
            setMinutes(m)

            const s = Math.floor(
                (difference % (1000 * 60)) / (1000)
            )
            setSeconds(s)


            // if(d <= 97 && h <= 0 && m <= 0 && s <= 0) setFedUntilTime(true)
            // setFedUntilTime(true)

        }, 1000)

        return () => clearInterval(interval)
    }, [props.farm_metadata])

    return (
        <>
            {props.farm_metadata === undefined ? 
                    <div className='countdown-container'>
                        <div className='title'> LOADING </div>
                        <div className='countdown-wrapper'>
                            <div className='countdown-inner'>
                                <div className='countdown-segment'>
                                    <span className='time'> --- </span>
                                    <span className='label'> days </span>
                                </div>
                                <span className='divider'> : </span>
                                <div className='countdown-segment'>
                                    <span className='time'> --- </span>
                                    <span className='label'> hours </span>
                                </div>
                                <span className='divider'> : </span>
                                <div className='countdown-segment'>
                                    <span className='time'> --- </span>
                                    <span className='label'> minutes </span>
                                </div>
                                <span className='divider'> : </span>
                                <div className='countdown-segment'>
                                    <span className='time'> --- </span>
                                    <span className='label'> seconds </span>
                                </div>
                            </div>
                        </div>
                    </div> :
                <div className='countdown-container'>
                <div className='title'> Last Gathered </div>
                <div className='countdown-wrapper'>
                    <div className='countdown-inner'>
                        <div className='countdown-segment'>
                            <span className='time'> {days} </span>
                            <span className='label'> days </span>
                        </div>
                        <span className='divider'> : </span>
                        <div className='countdown-segment'>
                            <span className='time'> {hours} </span>
                            <span className='label'> hours </span>
                        </div>
                        <span className='divider'> : </span>
                        <div className='countdown-segment'>
                            <span className='time'> {minutes} </span>
                            <span className='label'> minutes </span>
                        </div>
                        <span className='divider'> : </span>
                        <div className='countdown-segment'>
                            <span className='time'> {seconds} </span>
                            <span className='label'> seconds </span>
                        </div>
                    </div>
                </div>
            </div>
                    
            }
  



        </>















        // <>

  
        //     {
        //         fedUntilTime ? 

                
        //         :
        //         <div className='countdown-container'>
        //             <div className='title'> Food Runs Out: </div>
        //             <div className='countdown-wrapper'>
        //                 <div className='countdown-inner'>
        //                     <div className='countdown-segment'>
        //                         <span className='time'> {days} </span>
        //                         <span className='label'> days </span>
        //                     </div>
        //                     <span className='divider'> : </span>
        //                     <div className='countdown-segment'>
        //                         <span className='time'> {hours} </span>
        //                         <span className='label'> hours </span>
        //                     </div>
        //                     <span className='divider'> : </span>
        //                     <div className='countdown-segment'>
        //                         <span className='time'> {minutes} </span>
        //                         <span className='label'> minutes </span>
        //                     </div>
        //                     <span className='divider'> : </span>
        //                     <div className='countdown-segment'>
        //                         <span className='time'> {seconds} </span>
        //                         <span className='label'> seconds </span>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     }


        // </>
    )
}
function mapStateToProps(state) {
    return {
        farm_metadata : farmMetaDataSelector(state),
    }
  }
  
  export default connect(mapStateToProps)(Countup);
