import { createSelector } from 'reselect'
import { get } from 'lodash'


const bpfoodContract = state => get(state, 'BPFoodReducer.bp_food_contract.contract')
export const bpfoodContractSelector = createSelector(bpfoodContract, c => c)

const bpfoodMetaData = state => get(state, 'BPFoodReducer.bp_food_contract.metadata')
export const bpfoodMetaDataSelector = createSelector(bpfoodMetaData, d => d)

const bpfoodBalances = state => get(state, 'BPFoodReducer.bp_food_contract.balances')
export const bpfoodBalancesSelector = createSelector(bpfoodBalances, d => d)