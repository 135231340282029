import React from 'react'

// import { Web3Button } from '@web3modal/react'
// import { useWeb3ModalTheme } from '@web3modal/react'
import ConnectButton from './ConnectButton'

import '../Styles/Navbar.css'

const Navbar = () => {
    // const { theme, setTheme } = useWeb3ModalTheme()
    // setTheme({
    //     themeMode: 'dark',
    //     themeVariables: {
    //         '--w3m-font-family': 'Roboto, sans-serif',
    //         // '--w3m-accent-color': '#32cd32'
    //         '--w3m-accent-color': '#00ff95',
    //         '--w3m-accent-fill-color':'#5A5A5A'
    //         // ...
    //     }
    // })
  return (
    <>
    <div className='navbar'>
        {/* <div className='navbar-items'> */}
            <h1 className='navbar-items'>
                BASE PETS
            </h1>
            <div style={{marginRight:'50px'}}>
                <ConnectButton />
            </div>
        </div>
    {/* </div> */}
    </>
  )
}

export default Navbar


