export function base_petsContractLoaded(contract) {
    return {
      type: 'BASE_PETS_CONTRACT_LOADED',
      contract
    }
  }
  
  export function base_petsMetaDataLoaded(metadata) {
    return {
      type: 'BASE_PETS_METADATA_LOADED',
      metadata
    }
  }

  export function base_petsCostsLoaded(costs) {
    return {
      type: 'BASE_PETS_COSTS_LOADED',
      costs
    }
  }
  export function base_petsAvailablePets(available) {
    return {
      type: 'BASE_PETS_AVAILABLE_LOADED',
      available
    }
  }

//   export function base_petsGatherAmountLoaded(gatheramount) {
//     return {
//       type: 'BASE_PETS__GATHERAMOUNT_LOADED',
//       gatheramount
//     }
//   }