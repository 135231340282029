import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react'

import { useDispatch } from 'react-redux'

import { update } from '../store/interactions'
import { basepetsUpdate } from '../store/base_pets/interactions'
import { bpfoodUpdate } from '../store/bp_food/interactions'
import { boopUpdate } from '../store/boop/interactions'


import { bpfoodBalancesSelector, bpfoodMetaDataSelector } from '../store/bp_food/selectors'
import { basepetsMetaDataSelector } from '../store/base_pets/selectors'
import { boopDataSelector } from '../store/boop/selectors'

// import { BuildStructure } from './BuildStructure'
// import { UserStructure } from './UserStructure'

// import {images} from '../images'



// import { BrowserProvider, Contract } from 'ethers'
import { ethers } from 'ethers'

import bpFoodABI from '../backend/abis/BasePetFood.json'
const { BASE_PET_FOOD_ADDRESS } = require('../contracts.json')


// The ERC-20 Contract ABI, which is a common contract interface
// for tokens (this is the Human-Readable ABI format)


const BuyFood = (props) => {

  const dispatch = useDispatch()
  const { address, chainId, isConnected } = useWeb3ModalAccount()
  const { walletProvider } = useWeb3ModalProvider()

  const [feedAmount, setFeedAmount] = useState(0)
  const [petToFeed, setPetToFeed] = useState(0)
  const [foodID, setFoodID] = useState(0)
  const [purchaseAmount, setPurchaseAmount] = useState({})

//   const [txHash, setTxHash] = useState("")

  useEffect(() => {
    call_updates()
  }, [address])

  const call_updates = async () => {
    let new_address = isConnected ? address.toString() : ''
    await update(dispatch, new_address)
    await basepetsUpdate(dispatch, new_address)
    await bpfoodUpdate(dispatch, new_address)
    await boopUpdate(dispatch, new_address)
  } 
 

  const purchaseFood = async (food_id, food_amount) => {

    if(!isConnected) throw Error("User disconnected")

    const ethersProvider =  new ethers.BrowserProvider(walletProvider)
    const signer = await ethersProvider.getSigner()
    // The Contract object
    const bpFood = new ethers.Contract(BASE_PET_FOOD_ADDRESS, bpFoodABI.abi, signer)
    console.log(foodID, food_amount)
    if (foodID <= 0 || food_amount <= 0) {
        console.log("incorrect input amount")
    } else {
        let tx = await bpFood.purchaseFood(foodID, food_amount)
        await tx.wait()
        await call_updates()
        setPurchaseAmount(0)
        setFoodID(0)
    }
  }

  const adjustPurchaseAmount = (index, pos_neg_num) => {
    if (index === foodID) {
        let num = purchaseAmount + pos_neg_num
        num = num < 0 ? 0 : num
        setPurchaseAmount(num)

    } else {
        setFoodID(index)
        let num = pos_neg_num < 0 ? 0 : pos_neg_num
        setPurchaseAmount(num)
    }
  }
  const formatNums = (num) => {
    const options = {  maximumFractionDigits: 0   }   
    const formattedNumber = Intl.NumberFormat("en-US",options).format(num);
    return formattedNumber
  }


  return (
    <>
    {props.balance === undefined ||
    props.balance === null ||
    props.foodData === undefined ||
    props.foodData === null  ? <div>loading...</div> :
        
        <div style={{margin:50, marginBottom:100}}>
        <h1>purchase food</h1>
        <div><h2>Balance: {formatNums(props.balance.balance)} $BOOP</h2></div>
        <div className='Container-All-Card'>
        <table>
            <tbody>
                <tr>
                  <th>Name</th>
                  <th>ID</th>
                  <th>Time Increase(in Days)</th>
                  <th>Price</th>
                </tr>
        {props.foodData.map((food,index) => {
          return (

                <tr key={index}>
                  <td><b>{food.food_name}</b></td>
                  <td>{food.food_id}</td>
                  <td>{food.time_increase}</td>
                  <td>{food.price}</td>
                  <td>
                  <button
                    className='btn btn-primary' style={{margin:2}}
                    onClick={() => adjustPurchaseAmount(food.food_id, -10)}>
                        -10
                    </button>
                    <button
                        className='btn btn-primary' style={{margin:2}}
                        onClick={() => adjustPurchaseAmount(food.food_id, -1)}>
                        -
                    </button>
                    <b style={{margin:2}}>{foodID !== food.food_id ? 0 : purchaseAmount}</b>
                    <button
                        className='btn btn-primary' style={{margin:2}}
                        onClick={() => adjustPurchaseAmount(food.food_id, 1)}>
                        +
                    </button> 
                    <button
                        className='btn btn-primary' style={{margin:2}}
                        onClick={() => adjustPurchaseAmount(food.food_id, 10)}>
                        +10
                    </button>  
                  </td>
                  <td>{foodID !== food.food_id ? "" : "   " + purchaseAmount * food.price + "  BOOP"}</td>
                  <td>{foodID !== food.food_id ? "" : 
                    <button
                        className='btn btn-primary' style={{margin:2}}
                        onClick={() => purchaseFood(food.food_id, purchaseAmount)}>
                        purchase
                    </button> 
                  }</td>
                  
                </tr>
          )
        })}
        </tbody>
              </table>
        <div>

        </div>
        </div>
        </div>
        }
      </>
    )
}



function mapStateToProps(state) {
    return {
        balances: bpfoodBalancesSelector(state),
        foodData: bpfoodMetaDataSelector(state),
        balance: boopDataSelector(state),
    }
  }
  
  export default connect(mapStateToProps)(BuyFood);

