import { createSelector } from 'reselect'
import { get } from 'lodash'


const boopContract = state => get(state, 'BoopReducer.boopContract.contract')
export const boopContractSelector = createSelector(boopContract, c => c)


const boopData = state => get(state, 'BoopReducer.boopContract.data')
export const boopDataSelector = createSelector(boopData, d => d)
