import { combineReducers } from 'redux';

export function farm_contract(state = {}, action) {
  switch (action.type) {
    case 'FARM_CONTRACT_LOADED':
      return { ...state, loaded: true, contract: action.contract }
    case 'FARM_BALANCES_LOADED':
      return { ...state, loaded: true, balances: action.balances }
    case 'FARM_GATHERABLES_LOADED':
      return { ...state, loaded: true, gatherables: action.gatherables }
    case 'FARM_MAKEABLES_LOADED':
      return { ...state, loaded: true, makeables: action.makeables }
    case 'FARM_COSTS_LOADED':
        return { ...state, loaded: true, costs: action.costs }
    case 'FARM_METADATA_LOADED':
      return { ...state, loaded: true, metadata: action.metadata }
    default:
      return state
  }
}

const FarmReducer = combineReducers({
  farm_contract,
})

export default FarmReducer