import { ethers } from 'ethers'

import { update, loadProvider } from '../interactions'

import {
  base_petsContractLoaded,
  base_petsMetaDataLoaded,
  base_petsCostsLoaded,
  base_petsAvailablePets,
//   base_petsGatherAmountLoaded,
} from './actions'



import BASE_PETS from '../../backend/abis/BasePets.json'
const { BASE_PETS_ADDRESS } = require('../../contracts.json')

export const loadBasePets = async (dispatch, provider) => {
  try {
    const basepets = new ethers.Contract(BASE_PETS_ADDRESS, BASE_PETS.abi, provider)
    dispatch(base_petsContractLoaded(basepets))
    return basepets
  } catch (e) {
    console.log('Error, loading Base Pets: ', e)
    dispatch(base_petsContractLoaded(null))
    return null
  }
}


export const basepetsUpdate = async (dispatch, address) => {
  try{
    let provider, basepets, currentID
    // await update(dispatch)

    provider = await loadProvider(dispatch)
    // account = await loadAccount(dispatch)
    basepets = await loadBasePets(dispatch, provider)

    currentID = null
    currentID = await fetchTotalPets(dispatch, basepets)
    await fetchUserPetsIDs(dispatch, currentID, basepets, address)
    await fetchPetCosts(dispatch, basepets, address)
    await fetchAvailablePets(dispatch, currentID, basepets, provider)

  } catch (e) {
    console.log('Error, basepetsUpdate data: ', e)
  }
}

const fetchTotalPets = async (dispatch, basepets) => {
  const currentID = await basepets.totalSupply()

  return currentID.toString()
}

export const fetchUserPetsIDs = async (dispatch, currentID, basepets, account) => {

    let pet_info = []
  // try {
    let pet_ids
    if(typeof account === 'string' && account === '') {
      let address = "0xf39fd6e51aad88f6f4ce6ab8827279cfffb92266"

      pet_info[0] = {
        pet_id: 0,
        fed_until: 0
      }

    } else {
        pet_ids = await basepets.getOwned(account)
        if(pet_ids.length > 0) {
          for (let i = 0; i < pet_ids.length; i++) {
              let fed_until = await basepets.fedUntil(pet_ids[i])
            pet_info[i] = {
              pet_id: pet_ids[i],
              fed_until
            }
          }
      } else {
        pet_info[0] = {
          pet_id: 0,
          fed_until: 0
        }
      }
    }

    
    dispatch(base_petsMetaDataLoaded(pet_info))
  // } catch(e) {
  //   console.log('Error, unable to load user structures', e)
  // }
}



export const fetchPetCosts = async (dispatch, basepets, address) => {
  try{
    let costs

    let mint_costs = []
    let purchase_costs = []

    let fees = await basepets.getFees()
    let max_wallet = await basepets.max_wallet_size()
    let balanceOf = 0
    if(address !== undefined || address !== null || address !== "") {
      balanceOf = await basepets.balanceOf(address)
    }
    mint_costs = {
        mint_fee_eth: fees[0],
        mint_price: fees[1],
    }

    purchase_costs = {
        purchase_fee_eth: fees[2],
        purchase_price: fees[3],
    }
    let max_received = max_wallet - balanceOf
    costs = [mint_costs, purchase_costs, max_wallet, balanceOf, max_received]


    dispatch(base_petsCostsLoaded(costs))
  } catch (e) {
    console.log('Error, unable to load base pet costs' , e)
  }
}
export const fetchAvailablePets = async (dispatch, currentID, basepets, provider) => {
  try{
    let available = []
    if(currentID == null) currentID = await fetchTotalPets(dispatch, provider)
    let last = 1
    // for(let i = 1; i <= currentID; i++) {
    //     let next = (currentID * i) / currentID / 10
    //     let ids = await basepets.getAvailablePets(last, next)
    //     last = next
    //     ids = ids.filter(id => Number(id) !== 0)
    //     available.push(ids)
    // }

    available = await basepets.getAvailablePets(1, 5)
    available = available.filter((pet_id) => Number(pet_id) !== 0 );



    dispatch(base_petsAvailablePets(available))
  } catch (e) {
    console.log('Error, unable to fetch available pets ', e)
  }
}

export const feedPet = async (dispatch, pet_id, food_id, food_amount, signer) => {
  try {
    let provider = await loadProvider(dispatch)
    let basepets = await loadBasePets(dispatch, provider)
    await basepets.connect(signer).feedPet(pet_id, food_id, food_amount)


  } catch (e) {
    console.log(`Error, unable to feed ${pet_id} pet`, e)
  }
}