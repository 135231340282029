import { combineReducers } from 'redux';

export function bp_food_contract(state = {}, action) {
  switch (action.type) {
    case 'BASE_PETS_FOOD_CONTRACT_LOADED':
      return { ...state, loaded: true, contract: action.contract }
    case 'BASE_PETS_FOOD_METADATA_LOADED':
      return { ...state, loaded: true, metadata: action.metadata }
    case 'BASE_PETS_FOOD_BALANCES_LOADED':
      return { ...state, loaded: true, balances: action.balances }
    default:
      return state
  }
}

const BPFoodReducer = combineReducers({
  bp_food_contract,
})

export default BPFoodReducer