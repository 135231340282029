import Navbar from './Navbar'

import { useState } from 'react'
import { connect } from 'react-redux'

import BasePets from './BasePets'
import AvailablePets from './AvailablePets'
import Mint from './Mint'

import BPFood from './BPFood'
import BuyFood from './BuyFood'
import GetBOOP from './GetBOOP'
import Farm from './Farm'
import '../Styles/TabView.css'

const Main = () => {

  const [activeTab, setActiveTab] = useState(0)

  const activateTab = (index) => {
    console.log(index)
    setActiveTab(index)
  }

  // const BasePets = <BasePets />
  // const BPFood = <BPFood />
  // const BuyFood = <BuyFood />

  // const tabs = [
  //   {name:'Your Pets', content: BasePets},
  //   {name:'Minting', content: BPFood},
  //   {name:'Available Pets', content: BuyFood}
  // ]


  const tabs = [
    {name:'Your Pets'},
    {name:'Available Pets'},
    {name:'Mint-a-Pet'},
    {name:'Get $BOOP'},
    {name:'Farm'},
  ]

  const returnActiveTab =() => {
    switch (activeTab) {
      case 0:
        return (
          <>
            <BasePets />
            <BPFood />
            <BuyFood />
          </>
        )
      case 1:
        return (
          <>
            <AvailablePets />
          </>
        )
      case 2:
        return (
          <>
            <Mint />
          </>
        )
      case 3:
        return (
          <>
            <GetBOOP />
          </>
        )
      case 4:
        return (
          <>
            <Farm />
          </>
        )
      default:
        return (
          <>
            <BasePets />
            <BPFood />
            <BuyFood />
          </>
        )
    }

  }



  return (
    <>
        <Navbar />
      <div className='TabView'>
      <div className='body'>
        <div>
        <div className='tabs' >
          {tabs.map((tab, index) => {
            return (
              <label key={index} className={activeTab !== index ? 'tab' : 'active-tab'}
                onClick={() => activateTab(index)}
              >
                {tab.name}
              </label>
            )
          })}
        </div>
      </div>
        
        <div className='content'>
          {/* {tabs[activeTab].content} */}
          {returnActiveTab()}
        

      </div></div>
      </div>
    </>
  )
}



// function mapStateToProps(state) {
//     return {
//       // resources: resourcesBalancesSelector(state),
//       // resourcesContract: resourcesContractSelector(state),
//       // epoch: epochGatherAmountSelector(state),
//       // balance: balanceSelector(state),
//     }
//   }
  
export default connect(null)(Main);