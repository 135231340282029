import { createSelector } from 'reselect'
import { get } from 'lodash'


const farmContract = state => get(state, 'FarmReducer.farm_contract.contract')
export const farmContractSelector = createSelector(farmContract, c => c)

const farmBalances = state => get(state, 'FarmReducer.farm_contract.balances')
export const farmBalancesSelector = createSelector(farmBalances, d => d)

const farmGatherables = state => get(state, 'FarmReducer.farm_contract.gatherables')
export const farmGatherablesSelector = createSelector(farmGatherables, d => d)

const farmMakeables = state => get(state, 'FarmReducer.farm_contract.makeables')
export const farmMakeablesSelector = createSelector(farmMakeables, d => d)

const farmCosts = state => get(state, 'FarmReducer.farm_contract.costs')
export const farmCostsSelector = createSelector(farmCosts, d => d)

const farmMetaData = state => get(state, 'FarmReducer.farm_contract.metadata')
export const farmMetaDataSelector = createSelector(farmMetaData, d => d)