import { useState, useEffect} from 'react'
import { connect } from 'react-redux'
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react'

import { useDispatch } from 'react-redux'

import { update } from '../store/interactions'
import { basepetsUpdate } from '../store/base_pets/interactions'
import { bpfoodUpdate } from '../store/bp_food/interactions'
import { boopUpdate } from '../store/boop/interactions'


// import { createStructuredSelector } from 'reselect'

// import BPFood from './BPFood'
// import BuyFood from './BuyFood'
import Countdown from './Countdown'
import '../Styles/PetCard.css'


import { bpfoodBalancesSelector, bpfoodMetaDataSelector } from '../store/bp_food/selectors'
import { basepetsAvailableCostSelector, basepetsCostsSelector } from '../store/base_pets/selectors'
import { boopDataSelector } from '../store/boop/selectors'

// import { BuildStructure } from './BuildStructure'
// import { UserStructure } from './UserStructure'

// import {images} from '../images'



import { BrowserProvider, Contract, formatUnits } from 'ethers'

import basepetsABI from '../backend/abis/BasePets.json'


const { BASE_PETS_ADDRESS } = require('../contracts.json')


// The ERC-20 Contract ABI, which is a common contract interface
// for tokens (this is the Human-Readable ABI format)


const AvailablePets = (props) => {

  const dispatch = useDispatch()
  const { address, chainId, isConnected } = useWeb3ModalAccount()
  const { walletProvider } = useWeb3ModalProvider()

  const [feedAmount, setFeedAmount] = useState()
  const [petToFeed, setPetToFeed] = useState()
  const [foodID, setFoodID] = useState()



  const CallUpdates = async () => {
    // let account = useAccount()]
    // account.address === undefined ? address = '' : address = account.address.toString()
    
    
    let new_address = isConnected ? address.toString() : ''
    await update(dispatch, new_address)
    await basepetsUpdate(dispatch, new_address)
    await bpfoodUpdate(dispatch, new_address)
    await boopUpdate(dispatch, new_address)

  }
  useEffect(() => {
    CallUpdates()
  }, [address])

  const purchase_pet = async (pet_id) => {

    if(!isConnected) throw Error("User disconnected")

    const ethersProvider =  new BrowserProvider(walletProvider)
    const signer = await ethersProvider.getSigner()
    // The Contract object
    const basepets = new Contract(BASE_PETS_ADDRESS, basepetsABI.abi, signer)
    await basepets.purchaseAvailable(pet_id, { value: props.available_pet_costs[1].purchase_fee_eth })
    await CallUpdates()
  }
        return (
    <>
    <h1>Available Pets</h1>

      {
          props.available_pet_data === undefined ||
          props.available_pet_costs === undefined ||
          props.balance === undefined ? <div>loading...</div> :
          <div>


      <div className='Container-All-Card'>
        {props.available_pet_data.map((pet, index) => {
            return (
                
                <div className='pet-card' key={index}>
                <h1>{`Pet ID: ${(pet)}`}</h1>
                <div>
                <img src={require(`../images/${pet}.png`)}
                    width={'60%'}
                    alt={pet}
                />
                </div>
                <div>
                    <button
                    className='btn btn-primary'
                    onClick={() => purchase_pet(pet)}
                    disabled={props.available_pet_costs[4] > 0 ? false : true}
                    >
                        purchase
                    </button> 
                </div>

                </div>
            )
        })}
        </div>
        <div>
            <h3>Purchase Fees</h3>
        </div>
        <div>
            <h5>{Number(props.available_pet_costs[1].purchase_fee_eth)/10**18 + " $ETH"}</h5>
            <h5>{Number(props.available_pet_costs[1].purchase_price)/10**18 + " $BOOP"}</h5>
        </div>

        <br></br>
        <h6>You own <b>{Number(props.available_pet_costs[3])}</b> Base Pets! and you can own <b>{Number(props.available_pet_costs[4])}</b> more Base Pets!</h6>
        <br></br>

        <h6>Max Number of pets you can own is: {Number(props.available_pet_costs[2])}</h6>
        </div>
      }
      </>
    )
}



function mapStateToProps(state) {
    return {
        available_pet_data: basepetsAvailableCostSelector(state),
        available_pet_costs: basepetsCostsSelector(state),
        balance: boopDataSelector(state),
    }
  }
  
  export default connect(mapStateToProps)(AvailablePets);


// const mapStateToProps =  createStructuredSelector({
//   resources: bpfoodBalancesSelector,
// //   resourcesContract: bpfoodContractSelector,
//   user_pets_data: basepetsMetaDataSelector,
//   balance: boopDataSelector,
//   // activeMarkets: activeMarketsSelector,
//   // weightedAverages: weightedAveragesSelector,
// })

// export default connect(mapStateToProps)(BasePets)


            //           <img src={require(`../images/${props.resourcesMetadata.resourceNames[id]}.jpg`)}
            //             alt={props.resourcesMetadata.resourceNames[id]}