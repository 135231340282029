import { ethers } from 'ethers'

import { loadProvider } from '../interactions'

import {
  farm_ContractLoaded,
  farm_BalancesLoaded,
  farm_GatherablesLoaded,
  farm_MakeablesLoaded,
  farm_CostsLoaded,
  farm_MetaDataLoaded,
} from './actions'



import FARM from '../../backend/abis/Farm.json'
const { FARM_ADDRESS } = require('../../contracts.json')

export const loadFarm = async (dispatch, provider) => {
  try {
    const farm = new ethers.Contract(FARM_ADDRESS, FARM.abi, provider)
    dispatch(farm_ContractLoaded(farm))
    return farm
  } catch (e) {
    console.log('Error, loading farm contract: ', e)
    dispatch(farm_ContractLoaded(undefined))
    return undefined
  }
}

export const loadCurrentID = async (farm) => {
  try {
    const currentID = await farm.counter()
    const foodID = await farm.foodCounter()
    let counters = {
        currentID: currentID.toString(),
        foodID: foodID.toString()
    }
    return counters
  } catch (e) {
    console.log('Error, loading farm current ID: ', e)
    return undefined
  }
}

export const farmUpdate = async (dispatch, address) => {
  try{
    let provider, farm

    provider = await loadProvider(dispatch)
    farm = await loadFarm(dispatch, provider)

    const {currentID, foodID} = await loadCurrentID(farm)
    await fetchFarmBalances(dispatch, currentID, address, farm)
    await fetchFarmGatherables(dispatch, currentID, address, farm)
    await fetchFarmMakeables(dispatch, foodID, address, farm)
    await getFarmCosts(dispatch, farm)
    await getFarmData(dispatch, farm, currentID, foodID, address)

  } catch (e) {
    console.log('Error, on farm update: ', e)
  }
}

export const fetchFarmBalances = async (dispatch, currentID, account, farm) => {
  try {
    let accounts = []
    let ids = []
    let balances

    if(account === undefined || account === '') {
      balances = []
      for (let i = 0; i < currentID; i++) {
        balances[i] = 0
      }
    } else {
      for (let i = 1; i < currentID; i++) {
        accounts.push(account)
        ids.push(i)
      }
      balances = await farm.balanceOfBatch(accounts, ids)

    }
    dispatch(farm_BalancesLoaded(balances))
    return balances
  } catch(e) {
    console.log('Error, unable to load farm balances', e)
    dispatch(farm_BalancesLoaded(undefined))
    return undefined
  }
}

export const fetchFarmGatherables = async (dispatch, currentID, account, farm) => {
    try {

      let gatherables = []
      if(account === undefined || account === '') {
        for (let i = 0; i < currentID; i++) {
          gatherables.push(0)
        }

      } else {
        for (let i = 1; i < currentID; i++) {
            let gatherable = await farm.viewGather(i, account)
            gatherables.push(gatherable)
        }
      }
      dispatch(farm_GatherablesLoaded(gatherables))
      return gatherables
    } catch(e) {
      console.log('Error, unable to load farm gatherables', e)
      dispatch(farm_GatherablesLoaded(undefined))
      return undefined
    }
  }

  export const fetchFarmMakeables = async (dispatch, foodID, account, farm) => {
    try {

      let makeables = []

      if(account === undefined || account === '') {
        for (let i = 0; i < foodID; i++) {
          makeables.push(0)
        }

      } else {
        for (let i = 1; i < foodID; i++) {
            let makeable = await farm.makeFoodCheck(i, account)
            console.log(makeable)
            makeables.push(makeable)
        }
      }
      dispatch(farm_MakeablesLoaded(makeables))
      return makeables
    } catch(e) {
      console.log('Error, unable to load farm makeables', e)
      dispatch(farm_MakeablesLoaded(undefined))
      return undefined
    }
  }


export const getFarmCosts = async (dispatch, farm) => {
    let costs = []
    try {
        let buildFarmCost = await farm.buildFarmCost()
        let organicUpgradeCost = await farm.organicUpgradeCost()
        costs.push(Number(buildFarmCost) / 10**18)
        costs.push(Number(organicUpgradeCost) / 10**18)
        dispatch(farm_CostsLoaded(costs))
        console.log(costs)
        return costs
    } catch(e) {
        console.log('Error, unable to load farm costs', e)
        dispatch(farm_CostsLoaded(undefined))
        return undefined
    }
}

export const getFarmData = async (dispatch, farm, currentID, foodID, address) => {
    let FARMS = []
    let FOODS = []
    let OWNED_FARMS = []
    let hasFarm = false

    const FOODS_DATA = require('../DATA/food_data.json')
    const FARMS_DATA = require('../DATA/farm_data.json')

    try {
        if(address !== '' || address !== undefined) hasFarm = await farm.hasFarm(address)

        for(let i = 0; i < FARMS_DATA.length; i++) {
          FARMS.push({
            resourceName: FARMS_DATA[i].NAME,
            resourceID: FARMS_DATA[i].RESOURCE_ID,
            buildCost: FARMS_DATA[i].BUILD_COST,
            maxLevel: FARMS_DATA[i].MAX_LEVEL,
            rewardTime: FARMS_DATA[i].REWARD_TIME,
            requiredIDs: FARMS_DATA[i].REQUIRED_IDS,
            requiredAmounts: FARMS_DATA[i].REQUIRED_AMOUNTS,
          })
        }
        for(let i = 0; i < FOODS_DATA.length; i++) {
            FOODS.push({
              name: FOODS_DATA[i].NAME,
              bpFoodID: FOODS_DATA[i].FOOD_ID,
              inputResourceIDs: FOODS_DATA[i].REQUIRED_IDS,
              inputAmounts: FOODS_DATA[i].REQUIRED_AMOUNTS,
              organic: FOODS_DATA[i].ORGANIC,
            })
        }

        if (hasFarm) {
          for(let i = 1; i < currentID; i++) {
            
              let own_data = await farm.userStructures(address, i)

              OWNED_FARMS.push({
                resourceID: Number(own_data[0]),
                level: Number(own_data[1]),
                lastGathered: Number(own_data[2]),
                organic: own_data[3],
                built: own_data[4],
              })
            }
        }
        // FARMS = await farm.getResourceStructures()
        // for(let i = 1; i < foodID; i++) {
        //     let data = await farm.basePetFood(i)

        //     FOODS.push({
        //       bpFoodID: Number(data[0]),
        //       inputResourceIDs: data[1],
        //       inputAmounts: data[2],
        //       organic: Number(data[3]),
        //     })
        // }

        


        dispatch(farm_MetaDataLoaded([FARMS, FOODS, OWNED_FARMS, hasFarm]))
        console.log([FARMS, FOODS, OWNED_FARMS, hasFarm])
        return [FARMS, FOODS, OWNED_FARMS, hasFarm]
    } catch(e) {
        console.log('Error, unable to load farm', e)
        dispatch(farm_MetaDataLoaded(undefined))
        return undefined
    }
}