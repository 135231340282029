export function farm_ContractLoaded(contract) {
    return {
      type: 'FARM_CONTRACT_LOADED',
      contract
    }
  }

  export function farm_BalancesLoaded(balances) {
    return {
      type: 'FARM_BALANCES_LOADED',
      balances
    }
  }

  export function farm_GatherablesLoaded(gatherables) {
    return {
      type: 'FARM_GATHERABLES_LOADED',
      gatherables
    }
  }

  export function farm_MakeablesLoaded(makeables) {
    return {
      type: 'FARM_MAKEABLES_LOADED',
      makeables
    }
  }

  export function farm_CostsLoaded(costs) {
    return {
      type: 'FARM_COSTS_LOADED',
      costs
    }
  }

  export function farm_MetaDataLoaded(metadata) {
    return {
      type: 'FARM_METADATA_LOADED',
      metadata
    }
  }

