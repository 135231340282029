import { ethers } from 'ethers'

import { update, loadProvider, loadAccount } from '../interactions'

import {
  boopContractLoaded,
  boopDataLoaded,
} from './actions'



import BOOP from '../../backend/abis/BOOP.json'

const { BOOP_ADDRESS } = require('../../contracts.json')

export const loadBOOP = async (dispatch, provider) => {
  try {
    const boop = new ethers.Contract(BOOP_ADDRESS, BOOP.abi, provider)
    dispatch(boopContractLoaded(boop))
    return boop
  } catch (e) {
    console.log('Error, loading boop contract: ', e)
    dispatch(boopContractLoaded(undefined))
    return undefined
  }
}




export const boopUpdate = async (dispatch, address) => {
  try{
    let account, provider, boop
    // await update(dispatch)

    provider = await loadProvider(dispatch)
    // address = await loadAccount(dispatch)

    boop = await loadBOOP(dispatch, provider)


    await loadBOOPData(dispatch, boop, address)



  } catch (e) {
    console.log('Error, stables update data: ', e)
  }
}



export const loadBOOPData = async (dispatch, boop, account) => {
  try{

    if(account !== undefined || account !== '') {

      const preBalance = await boop.balanceOf(account)
      const balance = Number(preBalance) / 10**18
  
      // const preAllowance = await boop.allowance(EPOCH_ADDRESS, account)
      // const allowance = Number(preAllowance) / 10**6
  
      let data = {
        balance,
        // allowance
      }
      dispatch(boopDataLoaded(data))
    } else {
      let data = {
        balance:0
      }
      dispatch(boopDataLoaded(data))
    }
    
  } catch (e) {
    console.log('Error, loading boop data', e)
  }
}






export const approveSpend = async (dispatch, amount) => {
  try{
    // const provider = await loadProvider(dispatch)
    // const account = await loadAccount(dispatch)
    // // const netId = await provider.getNetwork()
    // let newAmount = ethers.parseUnits(amount.toString(), 6)
    // const signer = await provider.getSigner()
    // let boop = new ethers.Contract(BOOP_ADDRESS, BOOP.abi, provider)


    // await boop.connect(signer).approve(EPOCH_ADDRESS, newAmount)
    //   .on('receipt', async (r) => {
    //     boopUpdate(dispatch)
    //   })
    //   .on('error',(error) => {
    //     console.error(error)
    //     window.alert(`There was an error!`)
    //     // boopUpdate(dispatch)
    //   })
  } catch (e){
    console.log('Error, approve', e)
  }
  
}