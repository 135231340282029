import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react'

import { useDispatch } from 'react-redux'

import { update } from '../store/interactions'
import { basepetsUpdate } from '../store/base_pets/interactions'
import { bpfoodUpdate } from '../store/bp_food/interactions'
import { boopUpdate } from '../store/boop/interactions'


import { bpfoodBalancesSelector, bpfoodMetaDataSelector } from '../store/bp_food/selectors'
import { basepetsMetaDataSelector } from '../store/base_pets/selectors'
import { boopDataSelector } from '../store/boop/selectors'

// import { BuildStructure } from './BuildStructure'
// import { UserStructure } from './UserStructure'

// import {images} from '../images'



// import { BrowserProvider, Contract } from 'ethers'
import { ethers } from 'ethers'
import '../Styles/PetCard.css'
import basepetsABI from '../backend/abis/BasePets.json'
const { BASE_PETS_ADDRESS } = require('../contracts.json')


// The ERC-20 Contract ABI, which is a common contract interface
// for tokens (this is the Human-Readable ABI format)


const BPFood = (props) => {

  const dispatch = useDispatch()
  const { address, chainId, isConnected } = useWeb3ModalAccount()
  const { walletProvider } = useWeb3ModalProvider()

  const [feedAmount, setFeedAmount] = useState()
  const [petToFeed, setPetToFeed] = useState()
  const [foodID, setFoodID] = useState(0)

//   const [txHash, setTxHash] = useState("")

//   useEffect(() => {
//     call_updates()
//   }, [txHash])

  const call_updates = async () => {
    let new_address = isConnected ? address.toString() : ''
    await update(dispatch, new_address)
    await basepetsUpdate(dispatch, new_address)
    await bpfoodUpdate(dispatch, new_address)
    await boopUpdate(dispatch, new_address)
  } 
 

  const feed_pet = async (pet_id, food_amount) => {

    if(!isConnected) throw Error("User disconnected")

    const ethersProvider =  new ethers.BrowserProvider(walletProvider)
    const signer = await ethersProvider.getSigner()
    // The Contract object
    const basepets = new ethers.Contract(BASE_PETS_ADDRESS, basepetsABI.abi, signer)
    console.log(pet_id, foodID, food_amount)
    if (foodID <= 0 || pet_id <= 0 || food_amount <= 0) {
        console.log("incorrect input amount")
    } else {
        let tx = await basepets.feedPet(pet_id, foodID, food_amount)
        await tx.wait()
        
        setPetToFeed()
        setFoodID(0)
        setFeedAmount()
        await call_updates()

    }
  }

  const foodIDInput = (food_id) => {
    foodID !== food_id ? setFoodID(food_id) : setFoodID(0)
    
  }


  return (
    <>
    <hr></hr>
    <h1 style={{marginTop:50}}>owned food</h1>
{        
        props.foodData === undefined ||
        props.foodData === null ||
        props.balances === null ||
        props.balances === undefined ? <div>loading</div> :
        <div className='Container-All-Card'>
        <div>
        <table>
            <tbody>
                <tr>
                  <th>Name</th>
                  <th>ID</th>
                  <th>Time Increase(in Days)</th>
                  <th>amount owned</th>
                </tr>
        {props.foodData.map((food,index) => {
          return (

                <tr key={index}>
                  <td><b>{food.food_name}</b></td>
                  <td>{food.food_id}</td>
                  <td>{food.time_increase / 2}</td>
                  <td>{Number(props.balances[index])}</td>
                  <td>
                    {foodID !== food.food_id ?
                    <button
                        className='btn btn-primary'
                        onClick={() => foodIDInput(food.food_id)}>
                        select
                    </button> :
                    <button
                        className='btn btn-primary'
                        onClick={() => foodIDInput(food.food_id)}>
                        deselect
                    </button>   
                    }
                  </td>
                  
                </tr>
          )
        })}
        </tbody>
              </table>
        <div>

          <form className='active-form'
            onSubmit={(event) => {
            event.preventDefault();
            feed_pet(petToFeed, feedAmount)
            }}
          >
          <input style={{width:'20%', padding:5}}
            value={petToFeed}
            onChange={e => setPetToFeed(e.target.value)}
            placeholder='enter pet ID'
            required
          />
          <input style={{width:'25%', padding:5}}
            value={feedAmount}
            onChange={e => setFeedAmount(e.target.value)}
            placeholder='enter food amnt'
            required
          />
            <button  type='submit' className='btn btn-primary' style={{marginTop:'0px'}} >
            feed
            </button>
          </form>

        </div>
        </div>
        </div>
        }
      </>
    )
}



function mapStateToProps(state) {
    return {
        balances: bpfoodBalancesSelector(state),
        foodData: bpfoodMetaDataSelector(state),
        user_pets_data: basepetsMetaDataSelector(state),
        balance: boopDataSelector(state),
    }
  }
  
  export default connect(mapStateToProps)(BPFood);

