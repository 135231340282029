import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react'

import { useDispatch } from 'react-redux'

import { update } from '../store/interactions'
import { basepetsUpdate } from '../store/base_pets/interactions'
import { bpfoodUpdate } from '../store/bp_food/interactions'
import { boopUpdate } from '../store/boop/interactions'


import { basepetsCostsSelector } from '../store/base_pets/selectors'
import { boopDataSelector } from '../store/boop/selectors'

// import { BuildStructure } from './BuildStructure'
// import { UserStructure } from './UserStructure'

// import {images} from '../images'



// import { BrowserProvider, Contract } from 'ethers'
import { ethers } from 'ethers'

import BASEPETS from '../backend/abis/BasePets.json'
const { BASE_PETS_ADDRESS } = require('../contracts.json')


// The ERC-20 Contract ABI, which is a common contract interface
// for tokens (this is the Human-Readable ABI format)


const Mint = (props) => {

  const dispatch = useDispatch()
  const { address, chainId, isConnected } = useWeb3ModalAccount()
  const { walletProvider } = useWeb3ModalProvider()

  const [mintAmount, setMintAmount] = useState(1)

//   const [txHash, setTxHash] = useState("")

  useEffect(() => {
    call_updates()
  }, [address])

  const call_updates = async () => {
    let new_address = isConnected ? address.toString() : ''
    await update(dispatch, new_address)
    await basepetsUpdate(dispatch, new_address)
    await bpfoodUpdate(dispatch, new_address)
    await boopUpdate(dispatch, new_address)
  } 
 

  const mintPet = async () => {

    if(!isConnected) throw Error("User disconnected")

    const ethersProvider =  new ethers.BrowserProvider(walletProvider)
    const signer = await ethersProvider.getSigner()
    // The Contract object
    const basepets = new ethers.Contract(BASE_PETS_ADDRESS, BASEPETS.abi, signer)
    console.log(mintAmount)
    let mint_value = Number(props.available_pet_costs[0].mint_fee_eth) * mintAmount
    
    let user_max_mint = Number(props.available_pet_costs[2]) - Number(props.available_pet_costs[3])
    console.log(mintAmount > user_max_mint)
    console.log(mint_value)
    console.log(mint_value)
    console.log(user_max_mint)

    if (mintAmount <= 0) {
        console.log("incorrect input amount")
    } else if(mintAmount > user_max_mint) {
        console.log("Max wallet size met!")
    } else {
        let tx = await basepets.mint(mintAmount, { value: mint_value.toString() })
        await tx.wait()
        await call_updates()
    }
  }

  const adjustMintAmount = pos_neg_num => {
        let user_max_mint = Number(props.available_pet_costs[2]) - Number(props.available_pet_costs[3])
        let num = mintAmount + pos_neg_num
        num = num < 1 ? 1 : num
        num = num > user_max_mint ? user_max_mint : num
        setMintAmount(num)
  }



  return (
    <>
    {props.balance === undefined || props.available_pet_costs === undefined ? <div>loading...</div> :
        <div className='container'><h1>Mint-a-Pet</h1>
        <button
            className='btn btn-primary' style={{margin:10}}
            onClick={() => adjustMintAmount(-10)}>
        <b>-5</b>
        </button>
        <button
            className='btn btn-primary' style={{margin:10}}
            onClick={() => adjustMintAmount(-1)}>
        <b>-</b>
        </button>
        <b style={{fontSize:25, margin:10}}>{mintAmount}</b>
        <button
            className='btn btn-primary' style={{margin:10}}
            onClick={() => adjustMintAmount(1)}>
        <b>+</b>
        </button> 
        <button
            className='btn btn-primary' style={{margin:10}}
            onClick={() => adjustMintAmount(5)}
        >
        <b>+5</b>
        </button>  
        <div>

        <button
            className='btn btn-primary' style={{margin:10}}
            onClick={() => mintPet(5)}
            disabled={Number(props.available_pet_costs[4])  > 0 ? false : true}
        >
        <b>{`MINT: ${mintAmount}`}</b>
        </button> 

        </div>
        <div>
                <h3>Mint Fees</h3>
                </div>
                <div>
                    <h5>{Number(props.available_pet_costs[0].mint_fee_eth)/10**18 + " $ETH"}</h5>
                    <h5>{Number(props.available_pet_costs[0].mint_price)/10**18 + " $BOOP"}</h5>
                    <br></br>
                    <h6><b>Fees to mint: {mintAmount}</b></h6>
                    <br></br>
                    <h6>Eth   cost: {Number(props.available_pet_costs[0].mint_fee_eth)/10**18 * mintAmount + " $ETH"}</h6>
                    <br></br>
                    <h6>BOOP cost: {Number(props.available_pet_costs[0].mint_price)/10**18 * mintAmount + " $BOOP"}</h6>
                    <br></br>
                    <h6>You own <b>{Number(props.available_pet_costs[3])}</b> Base Pets! You can own <b>{Number(props.available_pet_costs[4])}</b> more Base Pets!</h6>
                    <br></br>

                    <h6>Max Number of pets you can own is: {Number(props.available_pet_costs[2])}</h6>
                </div>
        </div>
        }
      </>
    )
}



function mapStateToProps(state) {
    return {
        available_pet_costs: basepetsCostsSelector(state),
        balance: boopDataSelector(state),
    }
  }
  
  export default connect(mapStateToProps)(Mint);

