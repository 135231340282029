import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react'

import { useDispatch } from 'react-redux'

import { update } from '../store/interactions'
import { basepetsUpdate } from '../store/base_pets/interactions'
import { bpfoodUpdate } from '../store/bp_food/interactions'
import { boopUpdate } from '../store/boop/interactions'


import { bpfoodBalancesSelector, bpfoodMetaDataSelector } from '../store/bp_food/selectors'
import { basepetsMetaDataSelector } from '../store/base_pets/selectors'
import { boopDataSelector } from '../store/boop/selectors'

// import { BuildStructure } from './BuildStructure'
// import { UserStructure } from './UserStructure'

// import {images} from '../images'



// import { BrowserProvider, Contract } from 'ethers'
import { ethers } from 'ethers'

import BOOP from '../backend/abis/BOOP.json'
const { BOOP_ADDRESS } = require('../contracts.json')


// The ERC-20 Contract ABI, which is a common contract interface
// for tokens (this is the Human-Readable ABI format)


const GetBOOP = (props) => {

  const dispatch = useDispatch()
  const { address, chainId, isConnected } = useWeb3ModalAccount()
  const { walletProvider } = useWeb3ModalProvider()

  useEffect(() => {
    call_updates()
  }, [address])

  const call_updates = async () => {
    let new_address = isConnected ? address.toString() : ''
    await update(dispatch, new_address)
    await boopUpdate(dispatch, new_address)
  } 
 

  const mintBoop = async () => {

    if(!isConnected) throw Error("User disconnected")

    const ethersProvider =  new ethers.BrowserProvider(walletProvider)
    const signer = await ethersProvider.getSigner()
    // The Contract object
    const boop = new ethers.Contract(BOOP_ADDRESS, BOOP.abi, signer)
    let tx = await boop.mint()
    await tx.wait()
    await call_updates()
  }

  const formatNums = (num) => {
    const options = {  maximumFractionDigits: 0   }   
    const formattedNumber = Intl.NumberFormat("en-US",options).format(num);
    return formattedNumber
  }

  return (
    <>
    {props.balance === undefined ? <div>loading...</div> :
        <div className='container'>
          <h1>Get $BOOP</h1>

          <h1>{formatNums(props.balance.balance)}</h1>
        <button
          className='btn btn-primary' style={{margin:10}}
          onClick={() => mintBoop()}
        >
        MINT $BOOP
        </button>  
        <div>

        </div>
        </div>
        }
      </>
    )
}



function mapStateToProps(state) {
    return {
        balance: boopDataSelector(state),
    }
  }
  
  export default connect(mapStateToProps)(GetBOOP);

