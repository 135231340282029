import { createSelector } from 'reselect'
import { get } from 'lodash'


const basepetsContract = state => get(state, 'BasePetsReducer.base_pets_contract.contract')
export const basepetsContractSelector = createSelector(basepetsContract, c => c)

const basepetsMetaData = state => get(state, 'BasePetsReducer.base_pets_contract.metadata')
export const basepetsMetaDataSelector = createSelector(basepetsMetaData, d => d)

const basepetsCosts = state => get(state, 'BasePetsReducer.base_pets_contract.costs')
export const basepetsCostsSelector = createSelector(basepetsCosts, d => d)

const basepetsAvailableCost = state => get(state, 'BasePetsReducer.base_pets_contract.available')
export const basepetsAvailableCostSelector = createSelector(basepetsAvailableCost, d => d)
